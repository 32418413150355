import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
          <defs>
            <clipPath id="12ea4ebc3c">
              <path
                d="M 20 11.660156 L 365 11.660156 L 365 299 L 20 299 Z M 20 11.660156 "
                clipRule="nonzero"
              />
            </clipPath>
            <clipPath id="1f074e5cc8">
              <path
                d="M 0 132 L 375 132 L 375 366.410156 L 0 366.410156 Z M 0 132 "
                clipRule="nonzero"
              />
            </clipPath>
          </defs>
          <g clipPath="url(#12ea4ebc3c)">
            <path
              fill="#81cc2a"
              d="M 84.230469 156.957031 L 121.578125 125.648438 L 121.578125 243.730469 L 84.230469 206.34375 Z M 149.59375 109.996094 L 186.941406 78.6875 L 186.941406 255.953125 L 160.367188 282.558594 L 149.59375 271.773438 Z M 214.953125 63.035156 L 252.300781 31.722656 L 252.300781 190.527344 L 214.953125 227.910156 Z M 83.515625 221.125 L 160.367188 298.054688 L 277.484375 180.8125 L 254.894531 158.207031 L 364.5625 128.789062 C 339.933594 60.566406 274.667969 11.800781 198.019531 11.800781 C 100.238281 11.800781 20.972656 91.152344 20.972656 189.035156 C 20.972656 216.664062 27.300781 242.816406 38.566406 266.125 L 83.515625 221.125 "
              fillOpacity="1"
              fillRule="nonzero"
            />
          </g>
          <g clipPath="url(#1f074e5cc8)">
            <path
              fill="#53afe6"
              d="M 365.703125 132.019531 L 336.757812 240.152344 L 313.0625 216.433594 L 159.792969 342.648438 L 82.167969 252.347656 L 0.0429688 304.6875 L 49.992188 286.273438 C 81.640625 334.453125 136.113281 366.269531 198.019531 366.269531 C 295.800781 366.269531 375.0625 286.917969 375.0625 189.035156 C 375.0625 169.089844 371.773438 149.910156 365.703125 132.019531 "
              fillOpacity="1"
              fillRule="nonzero"
            />
          </g>
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
